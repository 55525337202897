import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa'; // Icons for social media

const Footer = () => {
  return (
    <footer className="bg-[#4157C4] text-white py-12 w-full" id='footer'>
      <div className="max-w-7xl mx-auto px-8 flex flex-col sm:flex-row justify-between">
        {/* Collaborate Info */}
        <div className="text-center sm:text-left">
          <h3 className="text-2xl font-bold mb-4 mt-10">Collaborative Rating Systems LLC</h3>
          <p>collaborate.us</p>
          <p className='hidden sm:block'>800-454-1984</p>
          <p className="mt-4 hidden sm:block">©2024 by FreightValidate, FreightCredit, Collaborate, FreightClub, What’sYourMCNumber</p>
        </div>

        <div className='flex flex-row mt-8 sm:mt-0 flex-wrap justify-between'>
        {/* Site Map */}
        {/* <div>
          <h3 className="text-lg font-bold mb-4 mt-10">Site Map</h3>
          <ul className='text-sm'>
            <li><a href="#" className="hover:underline">Home</a></li>
            <li><a href="#" className="hover:underline">About Us</a></li>
            <li><a href="#" className="hover:underline">Our Vision</a></li>
            <li><a href="#" className="hover:underline">Our Platforms</a></li>
          </ul>
        </div> */}

        {/* Others */}
        {/* <div>
          <h3 className="text-lg font-bold mb-4 mt-10">Others</h3>
          <ul className='text-sm'>
            <li><a href="#" className="hover:underline">Privacy Policy</a></li>
            <li><a href="#" className="hover:underline">Terms and Conditions</a></li>
          </ul>
        </div> */}

        {/* Contact Info */}
        <div className="text-center sm:text-left">
          <h3 className="text-lg font-bold mb-4 mt-10">Contact Us</h3>
          <p className='text-sm'>info@freightvalidate.com</p>
          <p className='block sm:hidden text-sm'>800-454-1984</p>
          <div className="flex flex-col mt-4 justify-center items-center sm:items-start">
          <h4 className="mt-6 font-bold">Follow us On</h4>
          <div className="flex space-x-4 mt-4 flex-col space-y-4 justify-center items-center">
            <a href="https://www.freightclub.us" className="hover:text-gray-300" target="_blank">
              <img src="/fc-logo.png" alt="FrightClub Logo" className="h-10" />
            </a>
            <a href="https://www.linkedin.com/company/collaborative-rating-systems-llc/" className="hover:text-gray-300" target="_blank">
              <FaLinkedinIn />
            </a>
          </div>
          <p className="mt-4 block sm:hidden text-center text-sm">©2024 by FreightValidate, FreightCredit, Collaborate, FreightClub, What’sYourMCNumber</p>
          </div>
        </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
