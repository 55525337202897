import AboutUsSection from "./Components/AboutUsSection";
import Footer from "./Components/Footer";
import HeroSection from "./Components/HeroSection";
import PlatformsSection from "./Components/PlatformsSection";

const Home = () => {
    return (
        <div className="w-full flex items-center justify-start flex-col">
            <HeroSection />
            <AboutUsSection />
            <PlatformsSection />
            <Footer />
        </div>
    );
};

export default Home;