import React from 'react';

const HeroSection = () => {
  return (
    <div className="bg-[#4157C4] min-h-[500px] flex flex-col justify-center items-center relative w-full px-4 sm:px-8">
      {/* Collaborate Logo */}
      <img
        src="/images/logo.png"
        alt="Collaborate Logo"
        className="absolute top-4 left-4 sm:top-6 sm:left-6 h-10 sm:h-14"
      />

      <div className='flex items-center mt-16 flex-col sm:flex-row w-full justify-center'>

      <div className="text-start text-center sm:text-left flex flex-col justify-center items-center sm:items-start">
        {/* Title and Subtitle */}
        <h1 className="text-white text-sm sm:text-sm mb-2">
          Welcome to
        </h1>
        <h2 className="text-white text-2xl sm:text-4xl mb-2">
          COLLABORATE.US
        </h2>
        <p className="text-white text-sm sm:text-lg mb-6 sm:mb-8">
        Collaborative Rating Systems: Unified Platforms, Trusted Results, Zero Tolerance for Fraud.
        </p>

        {/* Trailer Button */}
        <a
          href="https://www.youtube.com/watch?v=BQ7sqPNsXg8"
          className="bg-white text-black font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded-full hover:bg-gray-200 transition duration-300 text-sm w-[15rem] text-center"
        >
          <img src="/images/play.svg" alt="Play Icon" className="h-6 w-6 inline-block mr-2" /> OUR TRAILER VIDEO
        </a>
      </div>

       <div className="mt-10 sm:mt-0">
        <img
          src="/CollaboRateLogo.png"
          alt="Freight Image"
          className="h-48 sm:h-96 object-contain rounded-full"
        />
      </div>
    </div>

     

      {/* Contact Us Button */}
      <a
        href="#footer"
        className="absolute top-4 right-4 sm:top-6 sm:right-6 bg-white text-[#1129AB] py-2 px-4 rounded-[15px] hover:bg-gray-200 transition duration-300 text-sm"
      >
        CONTACT US
      </a>
    </div>
  );
};

export default HeroSection;
