import React from 'react';

const PlatformsSection = () => {
  const platforms = [
    {
      logo: '/images/platforms/freightvalidate-logo.png', // Replace with actual path
      title: 'FreightValidate',
      link: 'https://www.freightvalidate.com',
      description:
        'A robust verification platform ensuring the identities of freight participants through facial recognition and compliance checks.',
    },
    {
      logo: '/images/platforms/freightcredit-logo.png', // Replace with actual path
      title: 'FreightCredit.com',
      link: 'https://www.freightcredit.com',
      description:
        'A trusted credit rating and financial compliance platform that helps assess the financial stability of carriers and brokers.',
    },
    {
      logo: '/images/platforms/whatsyourmc-logo.png', // Replace with actual path
      title: 'What’sYourMCNumber.com',
      link: 'https://www.whatsyourmcnumber.com',
      description:
        'A quick compliance check tool for brokers and carriers without the need for a subscription.',
    },
    {
      logo: '/images/platforms/freightclub-logo.png', // Replace with actual path
      title: 'FreightClub.us',
      link: 'https://www.freightclub.us',
      description:
        'A private social network for validated freight industry stakeholders to collaborate and share insights on fraud prevention.',
    },
    {
      logo: '/images/platforms/thefreightlist-logo.png', // Replace with actual path
      title: 'TheFreightList.com',
      description:
        'A directory of the best and most trusted carriers, brokers, and industry professionals.',
    },
    {
      logo: '/images/platforms/fastfreightrate-logo.png', // Replace with actual path
      title: 'FastFreightRate.com',
      link: 'https://www.fastfreightrate.com',
      description:
        'An ad hoc pricing tool offering real-time freight rates based on market data, capacity, and historical trends.',
    },
    {
        logo: '/images/platforms/face-validate-logo.png', // Replace with actual path
        title: 'FaceValidate.com',
        link: 'https://www.facevalidate.com',
        description:
          `FaceValidate.com sets a new benchmark in secure, privacy-respecting facial recognition
technology tailored for the freight industry. With the power of Au10tix, a global leader in
identity verification and management, FaceValidate ensures unmatched accuracy and
reliability when verifying the identities of motor carriers, brokers, drivers, and other
stakeholders.`
      },
  ];

  return (
    <div className="bg-white py-16 px-8">
      <div className="max-w-7xl mx-auto text-center">
        {/* Heading */}
        <h2 className="text-black text-3xl font-bold mb-8">OUR PLATFORMS</h2>

        {/* Platforms Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {platforms.map((platform, index) => (
            <div
              key={index}
              className="border border-gray-200 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200 h-full flex flex-col justify-between cursor-pointer"
              onClick={() => window.open(platform.link, '_blank')}
            >
              <img
                src={platform.logo}
                alt={`${platform.title} Logo`}
                className="mx-auto h-24 mb-4 m-6"
              />
              <div className='flex flex-col text-left bg-[#F6F7FC] p-4 w-full'>
              <h3 className="text-black text-lg font-semibold mb-2">
                {platform.title}
              </h3>
              <p className="text-gray-600 text-sm">{platform.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlatformsSection;
